import React from 'react';
import './styles.scss';

const Contact = () => {
  return (
    <div className="Contact">
      <div className="Contact__content">
        <h1>Want to drop us a line?</h1>

        <a href="mailto:contact@getlobee.com">contact@getlobee.com</a>
      </div>
    </div>
  );
};

export default Contact;
