import React from 'react';
import Features from 'components/Features';
import illustration from 'imgs/illustration.svg';
import FEATURES from './features';
import './styles.scss';

function Home() {
  return (
    <div className="Home container">
      <div className="Home__main-wrapper">
        <div className="Home__texts">
          <h1>
            Happier Guests
            <br />
            Better Reviews
            <br />
            More Revenue
          </h1>

          <div className="Home__description">
            With Lobee, you can better understand the needs of your guests, connect with each other
            and encourage participation in your hostel's activities
          </div>

          <a
            href="https://forms.gle/uxSEW8BW14Eq7buD7"
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Request a Demo
          </a>
        </div>

        <div className="Home__illustration">
          <img src={illustration} alt="People in an Hostel's lobby" />
        </div>
      </div>

      <div className="Home__how-wrapper">
        <h2>
          How does <span className="lobee">lobee</span> work?
        </h2>

        <div className="Home__features">
          <Features features={FEATURES} />
        </div>

        {/* <a className="button" href="https://admin.getlobee.com">
          Try it out! It's free!
        </a> */}

        <a
          href="https://forms.gle/uxSEW8BW14Eq7buD7"
          className="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request a Demo
        </a>
      </div>
    </div>
  );
}

export default Home;
