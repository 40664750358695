import feature1 from 'imgs/feature1.png';
import feature2 from 'imgs/feature2.png';
import feature3 from 'imgs/feature3.png';

export default [
  {
    id: 1,
    title: 'Promote your Events with our board',
    description:
      'On our board you can publish important information, discounts with partner stores and all your events, detailing what is happening, where and when.<br />Your guests will automatically receive this information on their phones, and so will always know what activities are available to enjoy in your hostel.',
    image: feature1,
  },
  {
    id: 2,
    title: 'Keep your guests connected',
    description:
      'Lobee’s chat will allow you to keep in touch with your guests. As an administrator, you can always keep your guests updated.<br />Your guests will be able to get to know each other and share their experiences together.',
    image: feature2,
  },
  {
    id: 3,
    title: 'Coming up!',
    description:
      "We are currently working side by side with an early-adopters group, aiming to improve the Lobee's experience, both for the guests and hostel managers.",
    image: feature3,
  },
];
