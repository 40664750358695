import React, { useState } from 'react';
import './styles.scss';

const baseUrl = 'https://us-central1-getlobee.cloudfunctions.net';

const Unsubscribe = props => {
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const email = props.match.params.email;

  const handleUnsubscribe = async () => {
    setIsLoading(true);
    await fetch(`${baseUrl}/common/users/unsubscribe`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    setIsLoading(false);
    setIsDone(true);
  };

  return (
    <div className="Unsubscribe container">
      {!isLoading && !isDone && (
        <div className="Unsubscribe__text">Are you sure you want to unsubscribe {email}?</div>
      )}

      {!isLoading && !isDone && <button onClick={handleUnsubscribe}>Yes, unsubscribe</button>}

      <div className="Unsubscribe__text">
        {isLoading && 'Loading...'}
        {isDone && 'Done. See you soon!'}
      </div>
    </div>
  );
};

export default Unsubscribe;
