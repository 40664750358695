import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Header from 'components/Header';
import Home from 'pages/Home';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfUse from 'pages/TermsOfUse';
import Generator from 'pages/Generator';
import Contact from 'pages/Contact';
import Unsubscribe from 'pages/Unsubscribe';

ReactGA.initialize('UA-159283495-2');

const withAnalytics = Component => props => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return <Component {...props} />;
};

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Switch>
        <Route path="/contact" component={withAnalytics(Contact)} />
        <Route path="/privacy-policy" component={withAnalytics(PrivacyPolicy)} />
        <Route path="/terms-of-use" component={withAnalytics(TermsOfUse)} />
        <Route path="/generator" component={withAnalytics(Generator)} />
        <Route path="/unsubscribe/:email" component={withAnalytics(Unsubscribe)} />
        <Route
          path="/ios"
          render={() => {
            const url = 'https://apps.apple.com/app/lobee/id1507090448';
            window.location.href = url;
            return <a href={url}>Redirecting to App Store...</a>;
          }}
        />
        <Route
          path="/android"
          render={() => {
            const url = 'https://play.google.com/store/apps/details?id=com.getlobee.lobee';
            window.location.href = url;
            return <a href={url}>Redirecting to Google Play...</a>;
          }}
        />
        <Route path="/" component={withAnalytics(Home)} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
