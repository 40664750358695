import React from 'react';
import logo from 'imgs/lobee.png';
import './styles.scss';

const Header = () => {
  return (
    <div className="Header container">
      <div className="Header__logo">
        <a href="/">
          <img src={logo} alt="Lobee logo" />
          <div className="lobee">lobee</div>
        </a>
      </div>

      <div className="Header__buttons">
        {/* <a className="button --secondary" href="https://admin.getlobee.com">
          Login
        </a>
        <a className="button" href="https://admin.getlobee.com">
          Sign in
        </a> */}

        <a
          href="https://forms.gle/uxSEW8BW14Eq7buD7"
          className="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request a Demo
        </a>
      </div>
    </div>
  );
};

export default Header;
