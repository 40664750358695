import React, { useState } from 'react';

const PIECES = {
  HI: ['Hi!', 'Hello!', 'Hi there!', 'Hello there!', 'Hey!'],
  IAM: [
    'My name is Claire, from Lobee (getlobee.com)',
    "I'm Claire, from Lobee (getlobee.com)",
    'My name is Claire Davis, from Lobee (getlobee.com)',
    "I'm Claire Davis, from Lobee (getlobee.com)",
    'I am Claire Davis, from Lobee (getlobee.com)',
  ],
  MAIN: [
    'I would like to contact your Engagement Director or any Manager of your Hostel. I would really appreciate it if you could forward this email to him or her.',
    'I’m sending this email because I need to contact your Engagement Director or any Manager of your Hostel. I would really appreciate it if you could forward this email to him or her.',
    'I would like to share a great opportunity with your Engagement Director or Manager of your Hostel. I would really appreciate it if you could forward this email to him or her.',
    'Hey! I have a great opportunity to share with you guys! I would really appreciate it if you could forward this email to your Engagement Director or Manager of your Hostel.',
    'Hey! I have a great opportunity to share with you guys! It would great if you could forward this email to your Engagement Director or Manager of your Hostel.',
  ],
  BYE: [
    'Bye! Have a nice day!',
    'Bye! Have a great day!',
    'Bye! Have a good one!',
    'All the best, bye!',
    'Best',
  ],
};

const SPACES = ['\n\n', '\n \n', '\n  \n'];

const getRand = () => [
  Math.floor(Math.random() * PIECES.HI.length),
  Math.floor(Math.random() * PIECES.IAM.length),
  Math.floor(Math.random() * PIECES.MAIN.length),
  Math.floor(Math.random() * PIECES.BYE.length),
];

const getSpace = () => SPACES[Math.floor(Math.random() * SPACES.length)];

const Generator = () => {
  const [name, setName] = useState('');
  const [index, setIndex] = useState(getRand());

  const showGenerator = name === 'claire123';

  if (!showGenerator) {
    return (
      <input
        type="text"
        placeholder="Your name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
    );
  }

  const text =
    PIECES.HI[index[0]] +
    getSpace() +
    PIECES.IAM[index[1]] +
    getSpace() +
    PIECES.MAIN[index[2]] +
    getSpace() +
    PIECES.BYE[index[3]];

  return (
    <div className="Generator container">
      <div>
        <textarea
          value={text}
          onClick={e => {
            e.target.select();
            document.execCommand('copy');
          }}
          readOnly
        />
      </div>

      <button onClick={() => setIndex(getRand())}>Generate</button>
    </div>
  );
};

export default Generator;
