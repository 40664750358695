import React from 'react';
import './styles.scss';

const Features = ({ features }) => {
  return (
    <div className="Features">
      {features.map(feature => (
        <div
          key={feature.id}
          className={`Features__feature ${feature.id === 2 ? 'Features__feature--inverted' : ''}`}
        >
          <div className="Features__img">
            <img src={feature.image} alt={`Screenshot of ${feature.title}`} />
          </div>

          <div className="Features__texts">
            <div className="Features__title">{feature.title}</div>
            <div
              className="Features__description"
              dangerouslySetInnerHTML={{ __html: feature.description }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Features;
